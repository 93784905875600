<template>
  <div>
      <NoLoginNav />
      <div class="succeed">
          <div>
            <p><a-icon type="check-circle" class="icon" /></p>
            <p><span>恭喜注册成功</span></p>
            <p><span>请妥善保管您的账户信息</span></p>
            <button class="apply-enter" @click="onHandlerApplication"><a-icon type="shopping-cart" />申请入驻</button>
          </div>
      </div>
      <FootMain />
  </div>
</template>

<script>
import NoLoginNav from '@/layout/NoLoginNav'
import FootMain from '@/layout/FootMain'

export default {
    components: {
        NoLoginNav,
        FootMain
    },
    methods:{
        onHandlerApplication(){
            this.$router.push('/application')
        }
    }
}
</script>

<style lang="less" scoped>
.succeed {
    margin: 0 auto;
    width: 1200px;
    background: #fff;
    height: 600px;
    margin-top: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: @border;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        text-align: center;
        &:nth-child(2) {
            font-size: 24px;
            line-height: 36px;
            color: #666666;
        }
        &:nth-child(3) {
            font-size: 16px;
            line-height: 36px;
            color: #999999;
        }
    }
    .apply-enter {
        background: #fff;
        width: 200px;
        border: 1px solid @primary;
        color: @primary;
        font-size: 14px;
        height: 45px;
        cursor: pointer;
        margin-top: 28px;
        line-height: 45px;
        i {
            font-size: 24px;
            margin-right: 6px;
        }
        &:hover {
            color: #fff;
            background: @primary;
        }
    }
}
.icon {
    font-size: 96px;
    color: @primary;
    margin-bottom: 12px;
}
</style>